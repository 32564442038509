<template>
  <v-row>
    <v-col class="mb-5" cols="auto" md="12" sm="12">
      <v-card>
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.ordersByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="vehicleId ? ordersBasicHeaders : ordersHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="orders"
          :search="searchOrderString"
          class="elevation-1"
          multi-sort>
          <template v-slot:item.status="{ item }">
            {{ getStatus(item.status) }}
          </template>

          <template v-slot:item.client_id="{ item }">
            {{ getClientsName(item.client_id) }}
          </template>

          <template v-slot:item.collector_id="{ item }">
            {{ getCollectorsName(item.collector_id) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <template v-if="!vehicleId">
                <v-toolbar-title>
                  {{ $t('tables.orders') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>
              </template>

              <v-text-field
                v-model="searchOrderString"
                append-icon="mdi-magnify"
                hide-details
                :label="$t('tables.search')"
                single-line></v-text-field>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn text v-on="{ ...on, ...tooltipOn }">
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('buttons.actions') }}
                  </span>
                </v-tooltip>
              </template>

              <v-list>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="editOrderItem(item)">
                      <v-icon class="mx-1" small v-bind="attrs">mdi-eye</v-icon>
                      <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                        {{ $t('buttons.open') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.open') }}</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          {{ $t('snackbar.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import i18n from '@/i18n/i18n';
import { ordersBasicHeaders, ordersHeaders } from '@/mixins/data-table-headers';

export default {
  name: 'OrdersTable',
  props: ['orders', 'collectors', 'clients', 'vehicleId'],

  data: () => ({
    searchOrderString: '',
    snackbar: {
      active: false,
      color: '',
      text: '',
    },
  }),
  computed: {
    ordersHeaders() {
      return ordersHeaders(i18n);
    },
    ordersBasicHeaders() {
      return ordersBasicHeaders(i18n);
    },
  },
  methods: {
    editOrderItem(item) {
      this.$emit('show-event', item);
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },
    getStatus(status) {
      const orderStatus = {
        accepted: 'orderStatus.accepted',
        client_pending: 'orderStatus.client_pending',
        collector_pending: 'orderStatus.collector_pending',
        canceled: 'orderStatus.canceled',
        completed: 'orderStatus.completed',
        deleted: 'orderStatus.deleted',
      };

      return orderStatus[status] ? i18n.t(orderStatus[status]) : '';
    },
    getCollectorsName(id) {
      let index = this.collectors.findIndex((x) => x.id == id);
      return this.collectors[index]?.name;
    },

    getClientsName(id) {
      let index = this.clients.findIndex((x) => x.id == id);
      return this.clients[index]?.company_name;
    },
  },
};
</script>
